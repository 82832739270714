import FullScreenLoader from '@/components/FullScreenLoader/FullScreenLoader.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import ReportDataTable from '@/components/Report/ReportForm/ReportDataTable/ReportDataTable.vue'
import ReportMixin from '@/mixins/report.mixin'
import ExportMixin from '@/mixins/export.report.mixin'
import { _ } from 'core-js'

export default {
  props: ['id', 'filterId', 'filterProp'],
  components: {
    FullScreenLoader,
    ReportDataTable
  },
  mixins: [GraphQlMixin, ReportMixin, ExportMixin],
  data() {
    return {
      whereConditions: [],
      report: {},
      columns: [],
      selectedColumns: [],
      reportHeaders: [],
      reportItems: [],
      reportItemsCount: 0,
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      search: '',
      debouncing: false,
      debounceTimeout: null,
      filter: {},
      date: new Date().toISOString().substring(0, 7),
      menu: false
    }
  },
  computed: {
    subjects() {
      return this.$store.getters['graphql/reportTypes']
    },
    loading() {
      return this.$apollo.queries.report.loading
    },
    loaderMessage() {
      return this.loading ? 'Loading report' : ''
    },
    fieldClass() {
      return {
        'px-0': this.$vuetify.breakpoint.xsAndDown,
        'px-4': this.$vuetify.breakpoint.smAndUp
      }
    },
    breadcrumbItems() {
      return [
        {
          text: 'Reports',
          disabled: false,
          to: { name: 'list-reports' },
          exact: true
        },
        { text: _.get(this, 'report.name', `Show #${this.id}`) }
      ]
    }
  },
  watch: {
    report: {
      handler(value) {
        this.columns = value.subject.fields
        let newHeaders = []
        _.forEach(value.headers, (v,k) => {
          newHeaders.push({
            text: v.label,
            value: v.name
          })
        })

        if (value.relatedTypes !== null) {
          _.forEach(value.relatedTypes, (v,k) => {
            _.forEach(v.fields, (f) => {
              if (typeof f === 'object') {
                // only up to 2nd level for now
                _.forEach(f.fields, (ff) => {
                  newHeaders.push({
                    text: `${_.startCase(v.name)} ${_.startCase(f.name)} ${_.startCase(ff)}`,
                    value: `${v.name}.${f.name}.${ff}`
                  })
                })
              } else {
                newHeaders.push({
                  text: `${_.startCase(v.name)} ${_.startCase(f)}`,
                  value: `${v.name}.${f}`
                })
              }
            })
          })
        }

        this.reportHeaders = newHeaders
        this.selectedColumns = _.difference(value.headers, this.columns)
        let selectedColumnNames = this.selectedColumns.map(x => x.name)
        this.columns = _.filter(this.columns, x => !selectedColumnNames.includes(x.name))
        this.columns = _.concat(this.columns, this.selectedColumns)
      },
      deep: true
    },
    filter: {
      handler() {
        this.reload()
      },
      deep: true
    },
    filterProp: {
      handler() {
        if (this.filterProp) {
          this.filter = this.filterProp
        }
      }
    },
    date: {
      handler() {
        this.filter = JSON.parse(this.convertDateFilter(JSON.stringify(this.report.where)))
      }
    }
  },
  methods: {
    getVariables() {
      let whereExpression = null
      whereExpression = this.filterToWhereExpression(this.filter)
      return {
        take:
          this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
        skip:
          this.options.itemsPerPage === -1
            ? 0
            : this.options.itemsPerPage * (this.options.page - 1),
        orderBy: this.getOrderBy(),
        where: whereExpression,
        q: this.search
      }
    },
    reload() {},
    onFilterUpdate(filter) {
      this.filter = filter
    },
    convertDateFilter(condition) {
      let startOfMonth = this.$moment(this.date).startOf('month').format()
      let endOfMonth = this.$moment(this.date).endOf('month').format()

      condition = condition.replaceAll('currentMonthStart', startOfMonth)
      condition = condition.replaceAll('currentMonthEnd', endOfMonth)
      return condition
    }
  },
  apollo: {
    report: {
      query() {
        const fields = this.getFieldsGql('read', 'Report', [
          'id',
          'name',
          'description',
          'type',
          'relatedTypes',
          'headers',
          'where'
        ])
        return this.$gql`
          query GetReport($id: ID!) {
            report(id: $id) {
              ${fields}
            }
          }
        `
      },
      variables() {
        return { id: this.id }
      },
      skip() {
        return !this.id
      },
      update: () => null,
      result({ data, error }) {
        const report = data.report
        if (error) {
          this.graphQLOnError(`Failed to get report data from server. ${error.toString()}`)
          this.report = {}
          if (error.toString().includes('Not found')) {
            this.renderError(404)
          }
        } else {
          this.report = {
            ...this.report,
            ...{
              id: report.id,
              name: report.name,
              subject: JSON.parse(report.type),
              description: report.description,
              relatedTypes: JSON.parse(report.relatedTypes),
              headers: JSON.parse(report.headers),
              where: JSON.parse(report.where)
            }
          }
          this.filter = JSON.parse(this.convertDateFilter(report.where))
          this.$emit('name', this.report.name)
        }
      }
    },
    reportItems: {
      query() {
        let fieldsWithFields = []
        _.forEach(this.selectedColumns.filter((f) => f.fields), (v,k) => {
          fieldsWithFields.push({
            name: v.name,
            type: v.type,
            fields: v.fields
          })
        })

        let allFields = this.selectedColumns
          .filter((f) => !f.fields)
          .map(x => x.name)
          .concat(fieldsWithFields)
          

        if (this.report.relatedTypes !== null) {
          allFields = allFields.concat(this.report.relatedTypes)
        }
        let fields = this.getFieldsGql('read', this.report.subject.graphTypeName,
          allFields
        )
        return this.$gql`
          query GetReportItems($take: Int, $skip: Int, $orderBy: [OrderBy], $where: [WhereExpression], $q: String){
            reportItems: ${this.report.subject.listQueryName}(take: $take, skip: $skip, orderBy: $orderBy, where: $where, search: $q) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return this.getVariables()
      },
      skip() {
        return (
          !_.get(this, 'options.page')
          || this.debouncing
          || this.loadingQuery
          || !this.report.subject 
          || this.selectedColumns.length === 0
        )
      },
      result({ data, error }) {
        if (data && data.reportItems) {
          this.reportItems = data.reportItems.items
          this.reportItemsCount = data.reportItems.totalCount
        } else if (error) {
          this.graphQLOnError(`Failed to get list of report items. ${error.toString()}`)
        }
      }
    }
  }
}
