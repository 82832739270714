import ReportMixin from '@/mixins/report.mixin'
import ApiService from '@/services/api.service'
import { saveAs } from 'file-saver'

export default {
  mixins: [ReportMixin],
  data() {
    return {
      downloading: false
    }
  },
  methods: {
    downloadReports(format, subject) {
      const Where = this.filterToWhereExpression(this.filter)
      this.download(format, 'report', subject.name, Where)
      // subject.name should be in the following format:
      // DesktopDeployment
      // LaptopDeployment
      // MonitorDeployment
      // PcAccessory
      // MobileDeployment
      // SimDeployment
    },
    download(format, dataType, reportType, Where) {
      this.downloading = true

      ApiService.customRequest({
        method: 'post',
        url: '/export',
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob',
        data: {
          dataType,
          reportType,
          format,
          Search: this.search,
          Where
        }
      })
        .then(response => {
          saveAs(
            new Blob([response.data]),
            `${reportType} ${dataType} ${this.formatDisplayDateTime(new Date())}.${format === 'Excel' ? 'xlsx' : 'csv'}`
          )
        })
        .catch(error => {
          this.addError(null, `Download failed. ${error}`)
        })
        .finally(() => {
          this.downloading = false
        })
    }
  }
}
