var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"show-report",attrs:{"column":"","fill-height":""}},[_c('vue-headful',{attrs:{"title":((_vm._.get(_vm.report, 'name', ("Report #" + _vm.id))) + " | RAMS")}}),(_vm.loading)?_c('full-screen-loader',{attrs:{"message":_vm.loaderMessage}}):_vm._e(),_c('v-breadcrumbs',{staticClass:"pa-2",attrs:{"items":_vm.breadcrumbItems,"divider":">"}}),_c('v-layout',{staticClass:"section-row",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"hidden-xs-only pa-3"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('span',{staticClass:"display-1 font-weight-thin"},[_vm._v(" "+_vm._s(_vm._.get(_vm.report, 'name'))+" ")])]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"pt-4",attrs:{"label":"Select Year and Month","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.downloading,"id":"btn-download-reports"}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("save_alt")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])])]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"id":"list-item-export-to-excel"},on:{"click":function($event){return _vm.downloadReports('Excel', _vm.report.subject)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-excel")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Export "+_vm._s(_vm._.startCase(_vm.report.name))+" to Excel ")])],1)],1),_c('v-list-item',{attrs:{"id":"list-item-export-to-csv"},on:{"click":function($event){return _vm.downloadReports('Csv', _vm.report.subject)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-delimited")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Export "+_vm._s(_vm._.startCase(_vm.report.name))+" to CSV ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-layout',{staticClass:"section-row",attrs:{"row":"","wrap":""}},[_c('v-flex',{attrs:{"px-4":""}},[_c('report-data-table',{ref:"reportDatatable",attrs:{"reportItems":_vm.reportItems,"reportItemsCount":_vm.reportItemsCount,"displayedHeaders":_vm.reportHeaders,"loading":_vm.$apollo.loading,"options":_vm.options,"filter":_vm.filter},on:{"update:options":function($event){_vm.options=$event},"update":_vm.onFilterUpdate}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }